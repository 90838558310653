@import "../fonts/inter/stylesheet.css";
* {
  margin: 0;
  padding: 0;
  font-family: "inter_medium";
  box-sizing: border-box;
}
body {
  background-color: #fff;
}
input {
  outline: none;
  border: none;
}
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  display: none;
}
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
  -webkit-appearance: none;
}
.date-pick-payment{
  width: 100% !important;
  /* height: 60px !important; */
  background-color: transparent;
}
 .react-datepicker-wrapper{
  display: block !important;
  width: 100% !important;

}
::-webkit-scrollbar {
  -moz-appearance: none;
  display: none;
}
ul {
  list-style: none;
}


    #counter-value {
    }

    #btn-increment,
    #btn-decrement {
      font-size: 1em;
      padding: 10px;
      cursor: pointer;
    }

    #btn-increment {
      margin-left: 10px;
    }
/* * PAGINATION */

.pagination {
  display: flex;
  justify-content: center;
  cursor: pointer;
  color: #48538d;
  flex-wrap: wrap;
}
.pagination a {
  padding: 4px 14px;
  border-radius: 5px;
  color: #161619;
  background-color: #fff;
  font-size: 13px;
  margin: 0 5px 5px;
  display: inline-block;
}
.pagination li.break a {
  display: inline-block;
}
.previous .pagination__link {
  background-color: unset;
  color: #48538d;
}
.next .pagination__link {
  background-color: unset;
  color: #48538d;
}
.pagination__link {
  font-weight: bold;
}
.pagination__link--active a {
  color: #fff !important;
  background: #48538d;
}
.pagination__link--disabled a {
  cursor: not-allowed;
}
.active_link_classname {
  color: #fff;
  background: #48538d;
}
.ecAamZ {
  padding: 10px 0 15px !important;
}

/* pagination_ends */
.circular-label {
  width: 30px; /* Adjust the width and height to create a circular shape */
  height: 30px;
  background-color: #3498db; /* Background color of the circular label */
  border-radius: 50%; /* Make the label circular */
  text-align: center;
  line-height: 30px; /* Center text vertically */
  color: white; /* Text color */
  font-size: 12px; /* Font size */
}














.insurance-payment-container {
  font-family: Arial, sans-serif;
  width: 400px;
  margin: auto;
  text-align: center;
}

h1 {
  font-size: 24px;
}

.input-amount {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  font-size: 18px;
}

.emi-label {
  font-size: 14px;
  display: inline-block;
  margin-bottom: 10px;
}

#emi-count {
  color: #4a86e8;
}

.insurance.react-datepicker-wrapper{
  width: 100% !important;
}

.input-emi-count {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
}

.calculate-emi-button {
  width: 100%;
  padding: 10px;
  background-color: #4a86e8;
  color: white;
  font-size: 16px;
  cursor: pointer;
  border: none;
}

.calculate-emi-button:hover {
  background-color: #3568c5;
}
@media (max-width:1080px){
  .scroll{
    min-width: 1280px;
    overflow-x: scroll;
  }
  .parent-scroll{
    overflow: scroll;
  }
}