@font-face {
  font-family: "inter_light";
  src: url("Inter-ExtraLightItalic.woff2") format("woff2"),
    url("Inter-ExtraLightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "inter_bold";
  src: url("Inter-Bold.woff2") format("woff2"),
    url("Inter-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "inter_extrabold";
  src: url("Inter-ExtraBold.woff2") format("woff2"),
    url("Inter-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "inter_bolditalic";
  src: url("Inter-ExtraBoldItalic.woff2") format("woff2"),
    url("Inter-ExtraBoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "inter_black";
  src: url("Inter-Black.woff2") format("woff2"),
    url("Inter-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "inter_blackitalic";
  src: url("Inter-BlackItalic.woff2") format("woff2"),
    url("Inter-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "inter_extralight";
  src: url("Inter-ExtraLight.woff2") format("woff2"),
    url("Inter-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "inter_bolditalic";
  src: url("Inter-BoldItalic.woff2") format("woff2"),
    url("Inter-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "inter_mediumitalic";
  src: url("Inter-MediumItalic.woff2") format("woff2"),
    url("Inter-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "inter_lightitalic";
  src: url("Inter-LightItalic.woff2") format("woff2"),
    url("Inter-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "inter_medium";
  src: url("Inter-Medium.woff2") format("woff2"),
    url("Inter-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "inter_italic";
  src: url("Inter-Italic.woff2") format("woff2"),
    url("Inter-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "inter_light";
  src: url("Inter-Light.woff2") format("woff2"),
    url("Inter-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "inter_regular";
  src: url("Inter-Regular.woff2") format("woff2"),
    url("Inter-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "inter_semibold";
  src: url("Inter-SemiBold.woff2") format("woff2"),
    url("Inter-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "inter_semibolditalic";
  src: url("Inter-SemiBoldItalic.woff2") format("woff2"),
    url("Inter-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "inter_thinitalic";
  src: url("Inter-ThinItalic.woff2") format("woff2"),
    url("Inter-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "inter_thin";
  src: url("Inter-Thin.woff2") format("woff2"),
    url("Inter-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: unset;
}
